import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import photo from './../../../assets/images/photographer-407068_1920.jpg'
import icon1 from './../../../assets/images/icons/1.jpg'
import Constants from "../../../Constants";
import axios from "axios";
import {Link} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeItemCard = () => {

  const [categories, setCategories] = useState([])
  const [listings, setListing] = useState([])
  const [category, setCategory] = useState(null)

  const getCategories = () => {
    axios.get(Constants.BASE_URL+'/get-categories').then(res=>{
      setCategories(res.data.data)
    })
  }
  const getListing = () => {
    axios.get(Constants.BASE_URL+'/get-listing?category_id='+category).then(res=>{
      setListing(res.data.data)
    })
  }

  const handleCategory = (id) => {
    setCategory(id)
  }
  useEffect(() => {
    getListing()
  }, [category]);


  useEffect(() => {
    getCategories()
    getListing()
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1
  };

  return (
    <div className={"container section-gap"}>
      <div className="row">
        <div className="col-md-12 my-5">
          <h1 className={"text-center text-uppercase text-theme"}>
            Find your best match
          </h1>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-11">
          <div className="category-area">
            {Object.keys(categories).length > 0 ?
                <Slider {...settings}>

                  {categories.map((category, index)=>(
                      <div key={index} onClick={()=>handleCategory(category.id)} className="category-item">
                        <img src={category.icon}  alt={category.name}/>
                        <p>{category.name}</p>
                      </div>
                  ))}

                </Slider>:null
            }

          </div>
        </div>
        <div className="col-md-1 filter">
          <button className="btn-filter">Filter</button>
        </div>
      </div>
        <div className="row">
          {listings.map((listing, index)=>(
              <div className="col-md-3 mt-4" key={index}>
                <Link to={'/listing/'+listing.slug}>
                  <div className="home-card-item h-100">
                    <div className="image-container">
                      <button className="btn-favorite">
                        <i className="fa-solid fa-heart"></i>
                      </button>
                      <span>New</span>
                      <img src={listing.cover_photo} className={"img-thumbnail"} alt={"photo"} />
                    </div>
                    <div className="content">
                      <h6>{listing.title}</h6>
                      <p>{listing.description}</p>
                      <p>Aug 1 – 7</p>
                      <p>
                        <strong>{listing.discount_price} </strong> Night
                      </p>
                    </div>
                  </div>
                </Link>

              </div>
          ))}


        </div>
      <div className="col-md-12 my-4 text-center">
        <button className={"view-more-button mt-4"}>View More</button>
      </div>
    </div>
  );}



  export default HomeItemCard;
