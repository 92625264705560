import React, { useState } from "react";
import { useEffect } from "react";
import { Button, ButtonGroup, Form, InputGroup, Modal } from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import SearchMenu from "../Pages/SearchMenu/SearchMenu";
import logo from './../../assets/images/logo.jpeg'
import logo_am from './../../assets/images/logo_am.jpeg'
import axios from "axios";
import Constants from "../../Constants";
import loader from  '../../assets/images/loader/bars.svg'
import Swal from "sweetalert2";
import {BRAND} from "../../GlobalFunction";


const Navbar = () => {
  const history = useHistory()
  const [handleMenoShow, setHandleMenuShow] = useState(false);
  const [searchMenoShow, setSearchMenoShow] = useState(false);
  const [active, setActive] = useState('')
  const [btnActive , setBtnActive] = useState('btn-active')

  const [showPassword , setShowPassword] = useState('password')
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showRegister, setShowRegister] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (localStorage.user_token !=undefined){
      history.push('/my-booking')
    }else{
      setShow(true)
    }

  }
  const handleMenuShow = ()=> {

    if (handleMenoShow === false) {
      setHandleMenuShow(true);
    } else {
      setHandleMenuShow(false);
    }

  }

  const handleSearchMenu = () => {
    if (searchMenoShow === false) {
      setSearchMenoShow(true);
    } else {
      setSearchMenoShow(false);
    }
  }

  const  handleLoginForm = () => {
    setShowLogin(true)
    setShowRegister(false)
    setBtnActive('btn-active')
  }

  const handleRegister = () => {
    setShowLogin(false);
    setBtnActive("btn-active");
    setShowRegister(true);
  };

  const handleShowPassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text')
    } else {
      setShowPassword("password");
    }
  }




  const [input, setInput] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [err, setErr] = useState([])

  const handleInput= (e) => setInput(prevState => ({...prevState, [e.target.name] : e.target.value}))

  const handleLogin = () =>{
    setIsLoading(true)
    axios.post(Constants.BASE_URL+'/login', input).then(res=>{
      setIsLoading(false)
      localStorage.user_email = res.data.email
      localStorage.user_name = res.data.name
      localStorage.user_photo= res.data.photo
      localStorage.user_token= res.data.token
      window.location.reload()
    }).catch(errors=>{
      if (errors.response.status === 422){
        setErrors(errors.response.data.errors)
        setErr(errors.message)
        setIsLoading(false)
      }
    })
  }
  const handleRegisterRequest = () =>{
    setIsLoading(true)
    axios.post(Constants.BASE_URL+'/register', input).then(res=>{
      setIsLoading(false)
      localStorage.user_email = res.data.email
      localStorage.user_name = res.data.name
      localStorage.user_photo= res.data.photo
      localStorage.user_token= res.data.token
      window.location.reload()
    }).catch(errors=>{
      if (errors.response.status === 422){
        setErrors(errors.response.data.errors)
        setErr(errors.message)
        setIsLoading(false)
      }
    })
  }


  // useEffect(() => {
  //   window.scrollTo(0, 0)
  //   if (localStorage.user_token !== undefined){
  //     history.push('/dashboard')
  //   }
  // }, []);


  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(Constants.BASE_URL+'/logout').then(res=>{
          localStorage.removeItem('user_email')
          localStorage.removeItem('user_name')
          localStorage.removeItem('user_photo')
          localStorage.removeItem('user_token')
          window.location.reload()
        }).catch(errors=>{
          localStorage.removeItem('user_email')
          localStorage.removeItem('user_name')
          localStorage.removeItem('user_photo')
          localStorage.removeItem('user_token')
          window.location.reload()
        })
      }
    })

  }


  return (
    <nav className="nav-area">
      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-md-3 d-md-block nav-logo">
            <h1>
              <Link to="/">
                <img src={BRAND == 'DF'? logo : logo_am} alt={"logo"} className={"logo"} />{" "}
              </Link>
            </h1>
          </div>
          <div onClick={handleSearchMenu} className="col-md-5 nav-drawer">
            <div className="drawer-item">
              <p onClick={() => setActive("where")}>Anywhere</p>
              <p onClick={() => setActive("checkIn")}>Any week</p>
              <div onClick={() => setActive("who")} className="add-guests">
                <p>Add guests</p>
                <span>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-md-block nav-settings">
            <div className="setting">
              <div className="host">
                <a href={`https://host.${BRAND == 'DF' ? 'dayfuna' : 'amaratithi'}.com`}>Become a Host</a>
              </div>
              <div className="language">
                <p>
                  <i className="fa-solid fa-earth-americas"></i>
                </p>
              </div>
              <button onClick={handleMenuShow} className="border-0 hamburger">
                <p>
                  <i className="fa-solid fa-bars-staggered"></i>
                </p>
                <p>
                  <i className="fa-solid fa-circle-user"></i>
                </p>
              </button>
            </div>
          </div>
        </div>
        {searchMenoShow && <SearchMenu active={active} />}
      </div>
      {handleMenoShow && (
        <div className="profile-menu d-flex flex-column">
          <p onClick={handleShow}>
            {localStorage.user_token != undefined ? localStorage.user_name :'Login/Signup'}
          </p>
          <span></span>
          {localStorage.user_token != undefined ?
              <Link to={"/my-booking"}>My Bookings</Link>:null
          }

          <Link to={"/"}>Host an experience</Link>
          <Link to={"/"}>Help</Link>
          {localStorage.user_token != undefined ?
              <p onClick={handleLogout}>Logout</p>:null
          }
        </div>
      )}

      {/* Login form */}
      <>
        <Modal
          onClick={() => setHandleMenuShow(false)}
          className="modal-container"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="border-0" closeButton></Modal.Header>
          <Modal.Title className="mx-auto text-uppercase">
            {!showLogin ? (
              <h2>
                Join now {BRAND == 'DF'? <><span style={{ color: "red" }}>day</span>funa</>  : <><span style={{ color: "red" }}>amar</span>atithi</>   }
              </h2>
            ) : (
              <h2>
                Welcome to {BRAND == 'DF'? <><span style={{ color: "red" }}>day</span>funa</> :  <><span style={{ color: "red" }}>amar</span>atithi</> }
              </h2>
            )}
          </Modal.Title>
          <Modal.Body className="join-now">
            <div className="text-center">
              <ButtonGroup className="mb-2">
                <Button
                  onClick={handleLoginForm}
                  className={`btn-login ${
                    showLogin ? btnActive : '' }`}
                >
                  Log in
                </Button>
                <Button
                  onClick={handleRegister}
                  className={`btn-signup ${!showLogin ? btnActive : ""}`}
                >
                  Sign up
                </Button>
              </ButtonGroup>
            </div>
            <Form className="input-container">
              {showLogin && (
                <>
                  <label className={'w-100 text-start mt-4 position-relative'}>
                    Email/Phone
                    <input
                        type={'text'}
                        className={'form-control'}
                        name={'email'}
                        value={input.email}
                        onChange={handleInput}
                    />
                    <p className={'login-error-msg'}><small>{errors.email != undefined ? errors.email[0] : null}</small></p>
                    <p className={'login-error-msg'}><small>{err.message}</small></p>
                  </label>
                  <label className={'w-100 text-start mt-4 position-relative'}>
                    password
                    <input
                        type={'password'}
                        className={'form-control'}
                        name={'password'}
                        value={input.password}
                        onChange={handleInput}
                    />
                    <p className={'login-error-msg'}><small>{errors.password != undefined ? errors.password[0] : null}</small></p>
                  </label>
                  <div className="d-grid  mt-4" style={{placeItems: 'center'}}>
                    <button disabled={isLoading} onClick={handleLogin} dangerouslySetInnerHTML={{__html: isLoading ? `<img alt="loader" class="loader" src="${loader}"'/><img alt="loader" class="loader" src="${loader}"'/>` : 'Login'}} className={'w-75 btn-sm btn btn-info'}/>
                  </div>
                </>
              )}
              {showRegister && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      placeholder="Enter your name"
                      autoFocus
                      name={'name'}
                      value={input.name}
                      onChange={handleInput}
                    />
                    <p className={'login-error-msg'}><small>{errors.name != undefined ? errors.name[0] : null}</small></p>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      className="input"
                      type="email"
                      placeholder="Enter your email"
                      name={'email'}
                      value={input.email}
                      onChange={handleInput}
                    />
                    <p className={'login-error-msg'}><small>{errors.email != undefined ? errors.email[0] : null}</small></p>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      placeholder="Enter your phone"
                      name={'phone'}
                      value={input.phone}
                      onChange={handleInput}
                    />
                    <p className={'login-error-msg'}><small>{errors.phone != undefined ? errors.phone[0] : null}</small></p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="input"
                        type={showPassword}
                        placeholder="Enter your password"
                        name={'password'}
                        value={input.password}
                        onChange={handleInput}
                      />
                      <p className={'login-error-msg'}><small>{errors.password != undefined ? errors.password[0] : null}</small></p>
                      <Button
                        onClick={handleShowPassword}
                        className="btn-show-pass input"
                        id="button-addon2"
                      >
                        {showPassword === "password" ? (
                          <i className="fa-solid fa-eye"></i>
                        ) : (
                          <i className="fa-solid fa-eye-slash"></i>
                        )}
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div className="d-grid  mt-4" style={{placeItems: 'center'}}>
                    <button disabled={isLoading} onClick={handleRegisterRequest} dangerouslySetInnerHTML={{__html: isLoading ? `<img alt="loader" class="loader" src="${loader}"'/><img alt="loader" class="loader" src="${loader}"'/>` : 'Register'}} className={'w-75 btn-sm btn btn-info'}/>
                  </div>
                </>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      </>
      <button id={'login_modal_button'} className={'d-none'} onClick={handleShow}/>
    </nav>
  );
};

export default Navbar;
