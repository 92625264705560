import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import $ from 'jquery'
import user from "../../../assets/images/user.jpg";
import axios from "axios";
import Constants from "../../../Constants";
import {useHistory, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import {BRAND} from "../../../GlobalFunction";

const BookingDetails = () => {
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment());
    const [listing, setListing] = useState([]);
    const [guest_input, set_guest_input] = useState(1);
    const [date_result, setDate_result] = useState(moment().format("D/M/YYYY"))

    const params = useParams()
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setDate_result(moment(start).format('D/M/YYYY') + ' - ' + moment(end).format('D/M/YYYY'))

    };

    const getListing = () => {
        axios.get(Constants.BASE_URL + '/get-listing-details/' + params.slug).then(res => {
            setListing(res.data.data)
        })
    }

    const handleGuestInput = (e) => {
        set_guest_input(e.target.value)
    }

    const handleReservation = () => {
        if (localStorage.user_token != undefined) {
            Swal.fire({
                title: 'Are you sure to Reservation?',
                text: "Are You agree with the terms & conditions of {BRAND == 'DF' ? 'Dayfuna' : 'Amar Atithi'}?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, I agree and Reserve!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let data = {guest: guest_input, startDate: startDate, endDate: endDate, id: listing.id}
                    axios.post(Constants.BASE_URL + '/booking', data).then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: res.data.cls,
                            toast: true,
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 3000
                        })
                        history.push('/my-booking')
                    })
                }
            })

        } else {
            $('#login_modal_button').trigger('click')
        }

    }

    const handleDateRage = () => {
        $('#date_range').slideToggle()
    }

    useEffect(() => {
        getListing()
        window.scrollTo(0, 0)
    }, [])

    return (<section>
        <div className="container">
            {Object.keys(listing).length > 0 ?
                <div className="row">
                    <div className="single-page-header col-12 mt-3">
                        <h3>{listing.title}</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="review ms-2 col-md-5 d-flex align-items-center">
                                        <i className="fa-solid fa-star" style={{fontSize: "14px"}}></i>
                                        <span> 0.0 </span>
                                        <span className="mx-1">-</span>
                                        <span> 0 reviews</span>
                                    </div>
                                    <div className="col-md-7 location">
                                        <span className="mx-1">-</span>
                                        <span> {listing.listing_addresses.city}, {listing.listing_addresses.state}, <strong>{listing.listing_addresses.country}</strong> </span>
                                    </div>
                                </div>
                            </div>

                            {/* Shear and save button */}

                            <div className="col-md-6 d-flex justify-content-end">
                                {/*<div className="me-2">*/}
                                {/*    <button className="btn-shear">*/}
                                {/*        <i className="fa-solid fa-arrow-up-from-bracket"></i> Share*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div>
                                    <button className="btn-shear">
                                        <i className="fa-solid fa-heart"></i>
                                        <a href=""> Save</a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* image gallery */}
                    <div className="col-12 mt-4">
                        <div className="single-page-gallery">
                            <div className="row gx-2">
                                <div className="col-md-6">
                                    <div>
                                        <img className="image-radius" src={listing.cover_photo} alt="Cover Photo"/>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-md-0 mt-2">
                                    <div className="row g-2">
                                        {listing.photos != undefined ? listing.photos.slice(0, 4).map((photo, index) => (
                                            <div className="col-6" key={index}>
                                                <div>
                                                    <img className="image-radius" src={photo.photo} alt=""/>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="single-item-container d-flex justify-content-between align-items-center">
                                    <div className="text-container">
                                        <div className="heading">
                                            <h3>{listing.property} <small>({listing.property_type})</small> hosted by {listing.host.name}</h3>
                                        </div>
                                        <div>
                                            <span>{listing.guest} guests</span>
                                            <span className="px-1">-</span>
                                            <span>{listing.bedroom} bedrooms</span>
                                            <span className="px-1">-</span>
                                            <span>{listing.bed} beds</span>
                                            <span className="px-1">-</span>
                                            <span>{listing.bathroom} baths</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item-container">
                                    <div className="items-container d-flex">
                                        <div className="icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        <div className="content">
                                            <h5>Great location</h5>
                                            <p>
                                                100% of recent guests gave the location a 5-star rating.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="items-container d-flex">
                                        <div className="icon">
                                            <i className="fa-solid fa-key"></i>
                                        </div>
                                        <div className="content">
                                            <h5>Great check-in experience</h5>
                                            <p>100% of recent guests gave the check-in process a 5-star
                                                rating.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="items-container d-flex">
                                        <div className="icon">
                                            <i className="fa-solid fa-calendar-days"></i>
                                        </div>
                                        <div className="content">
                                            <h5>Free cancellation for 48 hours.</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item-container">
                                    <div className="heading">
                                        <h2 className={'text-uppercase'}>
                                            {BRAND == 'DF'? <><span style={{ color: "red" }}>day</span>funa</>  : <><span style={{ color: "red" }}>amar</span>atithi</>   }
                                        </h2>
                                    </div>
                                </div>
                                <div className="single-item-container">
                                    <div>
                                        {listing.description}
                                    </div>
                                </div>
                                <div className="single-item-container">
                                    <div>
                                        <div className="mb-4">
                                            <h3>Where you'll sleep</h3>
                                        </div>
                                        <div className="row">
                                            <div className="card-container col-sm-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="icon">
                                                            <i className="fa-solid fa-bed"></i>
                                                        </div>
                                                        <div>
                                                            <h5 className="card-text">{listing.bedroom} Bedroom</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-container col-sm-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="icon">
                                                            <i className="fa-solid fa-bed"></i>
                                                        </div>
                                                        <div>
                                                            <h5 className="card-text">{listing.guest} Guest</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-container col-sm-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="icon">
                                                            <i className="fa-solid fa-bed"></i>
                                                        </div>
                                                        <div>
                                                            <h5 className="card-text">Bath {listing.bathroom}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item-container">
                                    <div className="my-2">
                                        <h3>What this place offers</h3>
                                    </div>
                                    <div className="row">
                                        {listing.amenity != undefined && Object.keys(listing.amenity).length > 0 ? listing.amenity.map((amenity, index) => (
                                            <div className="col-md-6 mt-4" key={index}>
                                                <div className="special-feature">
                                                    <div className="d-flex align-items-center ">
                                                        <div className="icon">
                                                            <img className={'amenity-photo'} src={amenity.icon} alt={'icon'}/>
                                                        </div>
                                                        <div className="content ms-4">
                                                            <h5 className={'text-theme'}>{amenity.name}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                                {/*<div className="single-item-container border-0">*/}
                                {/*    <div className="heading">*/}
                                {/*        <h3>14 nights in El Nido</h3>*/}
                                {/*        <span>Jul 21, 2022</span>*/}
                                {/*        <span className="px-1">-</span>*/}
                                {/*        <span>Aug 4, 2022</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="calender-container mt-4">*/}
                                {/*        <DatePicker*/}
                                {/*            className="set-calender"*/}
                                {/*            selected={startDate}*/}
                                {/*            onChange={onChange}*/}
                                {/*            startDate={startDate}*/}
                                {/*            endDate={endDate}*/}
                                {/*            selectsRange*/}
                                {/*            selectsDisabledDaysInRange*/}
                                {/*            inline*/}
                                {/*        />*/}
                                {/*        <div className="text-center">*/}
                                {/*            <button className="btn-clear">Clear</button>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="col-md-4 mt-4">
                                <div
                                    className="card px-2"
                                    style={{
                                        borderRadius: "17px",
                                    }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex my-2 justify-content-between">
                                            <h5 className="card-title">{BRAND == 'DF'? '$' : '৳'}1,837 night</h5>
                                            <div className="d-flex align-items-center me-3">
                                                <i
                                                    className="fa-solid fa-star"
                                                    style={{fontSize: "12px"}}
                                                ></i>
                                                <small className="ms-1">4.96</small>
                                                <span className="mx-1">-</span>
                                                <small>126 reviews</small>
                                            </div>
                                        </div>
                                        <div
                                            className="border"
                                            style={{
                                                borderRadius: "7px",
                                            }}
                                        >
                                            <div>
                                                <button
                                                    className="w-100 border-0"
                                                    style={{background: "transparent "}}
                                                >
                                                    <div className="p-2">
                                                        <p className="m-0">CHECKOUT</p>
                                                        <div onClick={handleDateRage} className={'booking'}>{date_result}</div>
                                                        <div id={'date_range'} className="date-picker-reservation" style={{display: 'none'}}>
                                                            <DatePicker
                                                                className="calender-body"
                                                                selected={startDate}
                                                                onChange={onChange}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                selectsRange
                                                                selectsDisabledDaysInRange
                                                                inline
                                                            />
                                                        </div>
                                                    </div>
                                                </button>
                                                <div className=" justify-content-between border-top"></div>
                                                <button
                                                    className="w-100 border-0"
                                                    style={{background: "transparent "}}
                                                >
                                                    <div className="d-flex align-items-center justify-content-between p-2">
                                                        <div className={'w-100'}>
                                                            <p className="m-0">GUESTS</p>
                                                            <select onChange={handleGuestInput} name={'guest'} className={'form-select form-select-sm w-100'}>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="my-3 text-center">
                                            <button
                                                className={"view-more-button mb-2"}
                                                style={{width: "100%"}}
                                                onClick={handleReservation}
                                            >
                                                Reserve
                                            </button>
                                            <span>You won't be charged yet</span>
                                        </div>
                                        <div>
                                            {listing.discount != null ?
                                                <>
                                                    <div className="d-flex my-2 justify-content-between">
                                                        <p>Per Night</p>
                                                        <p>{listing.original_price}</p>
                                                    </div>
                                                    <div className="d-flex my-2 justify-content-between">
                                                        <p>
                                                            <a href="">Discount</a>
                                                        </p>
                                                        <p className="text-success">{listing.discount}</p>
                                                    </div>
                                                </> : null
                                            }

                                            <div className="d-flex my-2 justify-content-between">
                                                <p>
                                                    <a href="">Payable Amount</a>
                                                </p>
                                                <p>{listing.discount != null ? listing.discount_price : listing.original_price}</p>
                                            </div>
                                            <div className="d-flex pt-1 mt-2 border-top justify-content-between">
                                                <strong>Total before taxes</strong>
                                                <strong>{listing.discount != null ? listing.discount_price : listing.original_price}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    {/*        <div className="col-12">*/}
                    {/*            <div className="single-item-container border-top">*/}
                    {/*                <div>*/}
                    {/*                    <div className="d-flex review-heading mt-4 align-items-center">*/}
                    {/*  <span className="me-1">*/}
                    {/*    <i className="fa-solid fa-star"></i>*/}
                    {/*  </span>*/}
                    {/*                        <h4 className="m-0">*/}
                    {/*                            4.96 <span>-</span> 126 reviews*/}
                    {/*                        </h4>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="row mt-3 gx-5">*/}
                    {/*                        <div className="col-5 mb-2 review-item">*/}
                    {/*                            <div className="d-flex justify-content-between align-items-center">*/}
                    {/*                                <p className="m-0">Cleanliness</p>*/}
                    {/*                                <div className="d-flex  align-items-center">*/}
                    {/*                                    <div className="progress">*/}
                    {/*                                        <div*/}
                    {/*                                            className="progress-bar"*/}
                    {/*                                            role="progressbar"*/}
                    {/*                                        ></div>*/}
                    {/*                                    </div>*/}
                    {/*                                    <span className="ms-1">4.5</span>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-5  mb-2 review-item">*/}
                    {/*                            <div className="d-flex justify-content-between align-items-center">*/}
                    {/*                                <p className="m-0">Cleanliness</p>*/}
                    {/*                                <div className="d-flex  align-items-center">*/}
                    {/*                                    <div className="progress">*/}
                    {/*                                        <div*/}
                    {/*                                            className="progress-bar"*/}
                    {/*                                            role="progressbar"*/}
                    {/*                                        ></div>*/}
                    {/*                                    </div>*/}
                    {/*                                    <span className="ms-1">4.5</span>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-5  mb-2 review-item">*/}
                    {/*                            <div className="d-flex justify-content-between align-items-center">*/}
                    {/*                                <p className="m-0">Cleanliness</p>*/}
                    {/*                                <div className="d-flex  align-items-center">*/}
                    {/*                                    <div className="progress">*/}
                    {/*                                        <div*/}
                    {/*                                            className="progress-bar"*/}
                    {/*                                            role="progressbar"*/}
                    {/*                                        ></div>*/}
                    {/*                                    </div>*/}
                    {/*                                    <span className="ms-1">4.5</span>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="col-5  mb-2 review-item">*/}
                    {/*                            <div className="d-flex justify-content-between align-items-center">*/}
                    {/*                                <p className="m-0">Cleanliness</p>*/}
                    {/*                                <div className="d-flex  align-items-center">*/}
                    {/*                                    <div className="progress">*/}
                    {/*                                        <div*/}
                    {/*                                            className="progress-bar"*/}
                    {/*                                            role="progressbar"*/}
                    {/*                                        ></div>*/}
                    {/*                                    </div>*/}
                    {/*                                    <span className="ms-1">4.5</span>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="row  mt-3">*/}
                    {/*                    <div className="col-md-6 mb-3">*/}
                    {/*                        <div className="d-flex align-items-center">*/}
                    {/*                            <div*/}
                    {/*                                className="flex-shrink-0 rounded-pill"*/}
                    {/*                                style={{with: "60px", height: "60px"}}*/}
                    {/*                            >*/}
                    {/*                                <img*/}
                    {/*                                    className="rounded-pill"*/}
                    {/*                                    style={{width: "100%", height: "100%"}}*/}
                    {/*                                    src={user}*/}
                    {/*                                    alt="..."*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                            <div className="flex-grow-1 ms-2">*/}
                    {/*                                <h5 className="m-0">Asif</h5>*/}
                    {/*                                <small>May 2022</small>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="mt-2">*/}
                    {/*                            <p className="m-0">*/}
                    {/*                                My family and I can not say enough about the wonderful*/}
                    {/*                                time we had. You have read the reviews and it is all*/}
                    {/*                                true. Simply one of the most relaxing and pampered*/}
                    {/*                                vacations we.*/}
                    {/*                            </p>*/}
                    {/*                            <a href="">Show more</a>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-6 mb-3">*/}
                    {/*                        <div className="d-flex align-items-center">*/}
                    {/*                            <div*/}
                    {/*                                className="flex-shrink-0 rounded-pill"*/}
                    {/*                                style={{with: "60px", height: "60px"}}*/}
                    {/*                            >*/}
                    {/*                                <img*/}
                    {/*                                    className="rounded-pill"*/}
                    {/*                                    style={{width: "100%", height: "100%"}}*/}
                    {/*                                    src={user}*/}
                    {/*                                    alt="..."*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                            <div className="flex-grow-1 ms-2">*/}
                    {/*                                <h5 className="m-0">Asif</h5>*/}
                    {/*                                <small>May 2022</small>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="mt-2">*/}
                    {/*                            <p className="m-0">*/}
                    {/*                                My family and I can not say enough about the wonderful*/}
                    {/*                                time we had. You have read the reviews and it is all*/}
                    {/*                                true. Simply one of the most relaxing and pampered*/}
                    {/*                                vacations we.*/}
                    {/*                            </p>*/}
                    {/*                            <a href="">Show more</a>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-6 mb-3">*/}
                    {/*                        <div className="d-flex align-items-center">*/}
                    {/*                            <div*/}
                    {/*                                className="flex-shrink-0 rounded-pill"*/}
                    {/*                                style={{with: "60px", height: "60px"}}*/}
                    {/*                            >*/}
                    {/*                                <img*/}
                    {/*                                    className="rounded-pill"*/}
                    {/*                                    style={{width: "100%", height: "100%"}}*/}
                    {/*                                    src={user}*/}
                    {/*                                    alt="..."*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                            <div className="flex-grow-1 ms-2">*/}
                    {/*                                <h5 className="m-0">Asif</h5>*/}
                    {/*                                <small>May 2022</small>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="mt-2">*/}
                    {/*                            <p className="m-0">*/}
                    {/*                                My family and I can not say enough about the wonderful*/}
                    {/*                                time we had. You have read the reviews and it is all*/}
                    {/*                                true. Simply one of the most relaxing and pampered*/}
                    {/*                                vacations we.*/}
                    {/*                            </p>*/}
                    {/*                            <a href="">Show more</a>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-6 mb-3">*/}
                    {/*                        <div className="d-flex align-items-center">*/}
                    {/*                            <div*/}
                    {/*                                className="flex-shrink-0 rounded-pill"*/}
                    {/*                                style={{with: "60px", height: "60px"}}*/}
                    {/*                            >*/}
                    {/*                                <img*/}
                    {/*                                    className="rounded-pill"*/}
                    {/*                                    style={{width: "100%", height: "100%"}}*/}
                    {/*                                    src={user}*/}
                    {/*                                    alt="..."*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                            <div className="flex-grow-1 ms-2">*/}
                    {/*                                <h5 className="m-0">Asif</h5>*/}
                    {/*                                <small>May 2022</small>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="mt-2">*/}
                    {/*                            <p className="m-0">*/}
                    {/*                                My family and I can not say enough about the wonderful*/}
                    {/*                                time we had. You have read the reviews and it is all*/}
                    {/*                                true. Simply one of the most relaxing and pampered*/}
                    {/*                                vacations we.*/}
                    {/*                            </p>*/}
                    {/*                            <a href="">Show more</a>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-6">*/}
                    {/*                        <button className={"view-more-button my-4"}>*/}
                    {/*                            Show all 126 reviews*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-12">*/}
                    {/*            <div className="single-item-container">*/}
                    {/*                <div className="d-flex align-items-center">*/}
                    {/*                    <div*/}
                    {/*                        className="flex-shrink-0 rounded-pill"*/}
                    {/*                        style={{with: "60px", height: "60px"}}*/}
                    {/*                    >*/}
                    {/*                        <img*/}
                    {/*                            className="rounded-pill"*/}
                    {/*                            style={{width: "100%", height: "100%"}}*/}
                    {/*                            src={user}*/}
                    {/*                            alt="..."*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-grow-1 ms-2">*/}
                    {/*                        <h5 className="m-0">Hosted by Philipe</h5>*/}
                    {/*                        <small>Joined in January 2015</small>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="review mt-2 col-md-5 d-flex align-items-center">*/}
                    {/*                    <div className="me-3">*/}
                    {/*                        <i*/}
                    {/*                            className="fa-solid fa-star"*/}
                    {/*                            style={{fontSize: "14px"}}*/}
                    {/*                        ></i>*/}
                    {/*                        <span className="ms-1">4.96</span>*/}
                    {/*                        <span className="mx-1">-</span>*/}
                    {/*                        <span>126 reviews</span>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <i*/}
                    {/*                            className="fa-solid fa-circle-check"*/}
                    {/*                            style={{fontSize: "14px"}}*/}
                    {/*                        ></i>*/}
                    {/*                        <span className="ms-1">4.96</span>*/}
                    {/*                        <span className="mx-1">-</span>*/}
                    {/*                        <span>Identity verified</span>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="co-hosts mt-2">*/}
                    {/*                    <div>*/}
                    {/*                        <h6>Co-hosts</h6>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="d-flex align-items-center">*/}
                    {/*                        <div*/}
                    {/*                            className="flex-shrink-0 rounded-pill"*/}
                    {/*                            style={{with: "40px", height: "40px"}}*/}
                    {/*                        >*/}
                    {/*                            <img*/}
                    {/*                                className="rounded-pill"*/}
                    {/*                                style={{width: "100%", height: "100%"}}*/}
                    {/*                                src={user}*/}
                    {/*                                alt="..."*/}
                    {/*                            />*/}
                    {/*                        </div>*/}
                    {/*                        <div className="flex-grow-1 ms-2">*/}
                    {/*                            <h6 className="m-0">Martin</h6>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-md-6">*/}
                    {/*                        <h6 className="my-2">During your stay</h6>*/}
                    {/*                        <p>*/}
                    {/*                            One of our Family member or staff is always available to*/}
                    {/*                            provide you some tips and advice about all the*/}
                    {/*                            activities and transfers available in the area and the*/}
                    {/*                            rest of Palawan islan*/}
                    {/*                        </p>*/}
                    {/*                        <a href="">read more</a>*/}
                    {/*                    </div>*/}
                    {/*                    <p className="my-2">Response rate: 100%</p>*/}
                    {/*                    <p>Response time: within an hour</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="col-6">*/}
                    {/*                    <button className={"view-more-button my-4"}>*/}
                    {/*                        Contact Host*/}
                    {/*                    </button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-12">*/}
                    {/*            <div className="single-item-container">*/}
                    {/*                <div className="heading">*/}
                    {/*                    <h5>Things to know</h5>*/}
                    {/*                </div>*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-md-4">*/}
                    {/*                        <div className="my-3">*/}
                    {/*                            <h6>House rules</h6>*/}
                    {/*                        </div>*/}
                    {/*                        <div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-clock"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Check-in: After 2:00 PM</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-clock"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Checkout: 10:00 AM</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-users-rays"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Pets are allowed</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-smoking"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Smoking is allowed</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="mt-2">*/}
                    {/*                                <button*/}
                    {/*                                    className="border-0"*/}
                    {/*                                    style={{background: "transparent"}}*/}
                    {/*                                >*/}
                    {/*                                    <div className="d-flex align-items-center">*/}
                    {/*                                        <a href="">Show more</a>*/}
                    {/*                                        <span className="ms-1 pt-1">*/}
                    {/*            <i className="fa-solid fa-angle-right"></i>*/}
                    {/*          </span>*/}
                    {/*                                    </div>*/}
                    {/*                                </button>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-4">*/}
                    {/*                        <div className="my-3">*/}
                    {/*                            <h6>Health & safety</h6>*/}
                    {/*                        </div>*/}
                    {/*                        <div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-clock"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Check-in: After 2:00 PM</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-clock"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Checkout: 10:00 AM</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-users-rays"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Pets are allowed</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="d-flex my-1 align-items-center">*/}
                    {/*      <span className="me-1">*/}
                    {/*        <i className="fa-solid fa-smoking"></i>*/}
                    {/*      </span>*/}
                    {/*                                <p>Smoking is allowed</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="mt-2">*/}
                    {/*                                <button*/}
                    {/*                                    className="border-0"*/}
                    {/*                                    style={{background: "transparent"}}*/}
                    {/*                                >*/}
                    {/*                                    <div className="d-flex align-items-center">*/}
                    {/*                                        <a href="">Show more</a>*/}
                    {/*                                        <span className="ms-1 pt-1">*/}
                    {/*            <i className="fa-solid fa-angle-right"></i>*/}
                    {/*          </span>*/}
                    {/*                                    </div>*/}
                    {/*                                </button>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-4">*/}
                    {/*                        <div className="my-3">*/}
                    {/*                            <h6>Cancellation policy</h6>*/}
                    {/*                        </div>*/}
                    {/*                        <div>*/}
                    {/*                            <div>*/}
                    {/*                                <p className="mb-2">*/}
                    {/*                                    Free cancellation for 48 hours.*/}
                    {/*                                </p>*/}
                    {/*                                <p>*/}
                    {/*                                    Review the Host’s full cancellation policy which*/}
                    {/*                                    applies even if you cancel for illness or*/}
                    {/*                                    disruptions caused by COVID-19.*/}
                    {/*                                </p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="mt-2">*/}
                    {/*                                <button*/}
                    {/*                                    className="border-0"*/}
                    {/*                                    style={{background: "transparent"}}*/}
                    {/*                                >*/}
                    {/*                                    <div className="d-flex align-items-center">*/}
                    {/*                                        <a href="">Show more</a>*/}
                    {/*                                        <span className="ms-1 pt-1">*/}
                    {/*            <i className="fa-solid fa-angle-right"></i>*/}
                    {/*          </span>*/}
                    {/*                                    </div>*/}
                    {/*                                </button>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-12">*/}
                    {/*            <div className="single-item-container">*/}
                    {/*                <div className="heading my-3">*/}
                    {/*                    <h3>Explore other options in and around El Nido</h3>*/}
                    {/*                </div>*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-md-3 mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            Coron*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-3  mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            Coron Island*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-3  mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            Brother Island*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-3  mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            West Nalaut Island*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-3  mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            San Vicente*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-3  mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            Nacpan Beach*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-3  mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            Shimizu Island*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-3  mb-2">*/}
                    {/*                        <button*/}
                    {/*                            className="border-0"*/}
                    {/*                            style={{background: "transparent"}}*/}
                    {/*                        >*/}
                    {/*                            Linapacan Island*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="mt-3">*/}
                    {/*                    <span>Dayfuna</span>*/}
                    {/*                    <span className="mx-1">*/}
                    {/*  <i className="fa-solid fa-angle-right"></i>*/}
                    {/*</span>*/}
                    {/*                    <span>Philippines</span>*/}
                    {/*                    <span className="mx-1">*/}
                    {/*  <i className="fa-solid fa-angle-right"></i>*/}
                    {/*</span>*/}
                    {/*                    <span>MIMAROPA</span>*/}
                    {/*                    <span className="mx-1">*/}
                    {/*  <i className="fa-solid fa-angle-right"></i>*/}
                    {/*</span>*/}
                    {/*                    <span>Palawan</span>*/}
                    {/*                    <span className="mx-1">*/}
                    {/*  <i className="fa-solid fa-angle-right"></i>*/}
                    {/*</span>*/}
                    {/*                    <span>El Nido</span>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                        </div>
                    </div>
                </div> : null
            }
        </div>
    </section>);
};

export default BookingDetails;
