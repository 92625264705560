import React, {useEffect, useState} from 'react';
import axios from "axios";
import Constants from "../../Constants";
import {Link} from "react-router-dom";

const MyBooking = () => {
    const [orders, setOrders] = useState([])
    const getListing = () => {
        axios.get(Constants.BASE_URL + '/booking').then(res => {
            setOrders(res.data.data)
        })
    }
    useEffect(() => {
        getListing()
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={'container my-5'}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4>My Bookings</h4>
                        </div>
                        <div className="card-body">
                            <table className={'table table-hover table-bordered'}>
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                    <th>Details</th>
                                    <th>Order Place Time</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orders.map((order, index)=>(
                                    <tr key={index}>
                                        <td className={"text-center align-middle"}>{++index}</td>
                                        <td className={"text-center align-middle"}>{order.title}</td>
                                        <td className={"text-center align-middle"}>{order.category}</td>
                                        <td className={"text-center align-middle"}>${order.amount}</td>
                                        <td className={"text-center align-middle"}>{order.status}</td>
                                        <td className={"text-center align-middle"}>
                                            <p><small><span className={'text-success'}>{order.start_date}</span> to <span className={'text-info'}>{order.end_date}</span></small></p>
                                            <p>Total <strong className={'text-success'}>{order.total_day}</strong> Day{order.total_day >1 ? "'s":null}</p>
                                        </td>
                                        <td className={"text-center align-middle"}>
                                            <small>{order.created_at}</small>
                                        </td>
                                        <td className={"text-center align-middle"}>
                                            <Link to={"#"}><button className={'btn btn-sm btn-info'}><i className="fa-solid fa-eye"></i></button> </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MyBooking;
